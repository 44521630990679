import React, { useEffect, useState } from "react";
import { Grid, Stack } from "@mui/material";
import parse, { domToReact, Element, HTMLReactParserOptions } from 'html-react-parser';

import OverviewSection from "./OverviewSection.web";
import PlainTextItem from "./PlanTextItem.web";
import ItemWithImage from "./ItemWithImage.web";
import { CustomSectionContainer, SectionListLayout } from "./CustomContainer.web";

import { IContentData, ISectionData, ITermDetail } from "../../blocks/contentmanagement/src/ContentManagementController";

const WhyNot = ({ data }: { data: IContentData }) => {
  const { attributes } = data;
  const { title, terms } = attributes;

  const options: HTMLReactParserOptions = {
    replace(domNode) {
      if (!(domNode instanceof Element)) return;
      const replacementByName: { [key: string]: JSX.Element } = {
          "ul": (
            <Stack direction="column" sx={{ gap: '12px' }}>
              {domToReact(domNode.children, options)}
            </Stack>
          ),
          "li": <PlainTextItem>{domToReact(domNode.children, options)}</PlainTextItem>,
      };
      if (!replacementByName[domNode.name]) return;
      return replacementByName[domNode.name];
    }
  }

  const [sectionList, setSectionList] = useState<ISectionData[]>([]);
  useEffect(() => {   
    if (terms) {
      terms.sort((a, b) => a.id - b.id);      
      const groupedData: { [key: string]: ITermDetail[] } = {};
      terms.forEach(term => {
        const key = term.term;
        if (!groupedData[key]) {
          groupedData[key] = [];
        }
        groupedData[key].push(term);
      });
      const sections = Object.entries(groupedData).map(group => {
        if (group[1].length > 1) {
          return {
            name: group[0],
            definition: '',
            images: [],
            children: group[1].map(each => {
              return { definition: each.definition, images: each.images }
            })
          }
        }
        return {
          id: group[1][0].id,
          name: group[0],
          definition: group[1][0].definition,
          images: group[1][0].images,

        }
      })
      setSectionList(sections)
    }
  }, [terms]);


  return (
    <SectionListLayout>
      <OverviewSection 
        images={title ? title?.images.map(image => image.url) : []} 
        name={title && parse(title?.title)} 
        shortDesc={title && title?.desc && title?.desc !== '' && parse(title?.desc)}
      />
      {sectionList                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                               
        .map((section, index) => {
          if (section.children && section.children.length > 0 ) {
            return (
              <CustomSectionContainer
                key={index}
                withHeading
                heading={parse(section.name)}
                containerStyle={{ px: 2.5 }}
              >
                <Grid container spacing={2.5}>
                  {section?.children && section?.children.map((child, index) => (
                    <Grid key={index} item xs={12}>
                      <ItemWithImage 
                        image={child.images[0]?.url || ''} 
                        text={parse(child.definition)}
                        imageSize={120}
                        style={{ p: 0, background: 'transparent', border: 'none' }} 
                      />
                    </Grid>
                  ))}
                </Grid>
              </CustomSectionContainer>)}
            if (section.images && section.images.length > 0 ) {
              return (
                <CustomSectionContainer
                  key={index}
                  withHeading
                  heading={parse(section.name)}
                  containerStyle={{ px: 2.5 }}
                >
                  <Grid container spacing={2.5}>
                  <Grid key={index} item xs={12}>
                      <ItemWithImage 
                        image={section.images[0].url} 
                        text={parse(section.definition)}
                        imageSize={120}
                        style={{ p: 0, background: 'transparent', border: 'none' }} 
                      />
                    </Grid>
                  </Grid>
                </CustomSectionContainer>)}
          return (
            <CustomSectionContainer key={index} containerStyle={{ p: { xs: '40px 36px', md: '40px 150px'} }}>
              {parse(section.definition, options)}
            </CustomSectionContainer>
          )
        })}
    </SectionListLayout>
  );
}

export default WhyNot;
